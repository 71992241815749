import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout2'

const NotFoundPage = () => (
  <Layout>
    <div id="main" className="alt">
        <section id="one">
            <div className="inner">
                <h1>NOT FOUND</h1>
                <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
                <ul className="actions fit">
                  <li><a href="/" className="button fit">Back to the home page</a></li>
                </ul>
            </div>
        </section>
    </div>
  </Layout>
)

export default NotFoundPage
